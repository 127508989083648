.bg-primary {
    background-color: #111D5E !important;
}

.btn-pink {
    background-color: #FF467B;
    color: #fff;
    cursor: pointer;
}

a:hover, a.active {
    color: #FF467B;
}

.label {
    color: #FF467B;
}

.text-pink {
    color: #FF467B;
}
.border-pink {
    border-color: #FF467B;
}
.bg-pink-light {
    background-color: rgba(255, 70, 123, 0.3);
}
.hover\:text-pink:hover {
    color: #FF467B;
}
